import React from "react";
import CosService from "./CosService";
import { withRouter } from "react-router-dom";
import { interceptLinkClick } from "./utils";

class CosChapter extends React.Component {
  contentClickHandler = interceptLinkClick(this.props);
  render() {
    let cosServicesCount = this.props.cos_services.length;

    let cosServices = this.props.cos_services.map((cos_service) => (
      <CosService
        key={cos_service.id}
        cosServicesCount={cosServicesCount}
        {...cos_service}
        chapter_number={this.props.chapter_number}
        pageType={this.props.pageType}
        svgBadgeContent={this.props.svgBadgeContent}
      />
    ));

    let chapterLevel = this.props.chapter_number
      ? this.props.chapter_number.split(".").length
      : 0;
    let chapterClassName =
      "chapter chapter-level-" +
      chapterLevel +
      " chapter-index-" +
      this.props.index;
    let header = ["/guidelines", "/technical_leaflets"].includes(
      this.props.pathname
    ) ? (
      ""
    ) : (
      <div
        className="header"
        onClick={this.contentClickHandler}
        dangerouslySetInnerHTML={{ __html: this.props.header }}
      />
    );

    if (chapterLevel == 2) {
      if (
        this.props.chapter_number != null &&
        parseInt(this.props.chapter_number.split(".")[1]) > 1 &&
        this.props.pageType !== "/guidelines"
      ) {
        chapterClassName += " new-page";
      }
    }
    return (
      <div className={chapterClassName}>
        {this.props.chapter_number == "0" ||
        (["/guidelines", "/technical_leaflets"].includes(this.props.pageType) &&
          chapterLevel >= 3) ? null : (
          <h1>
            <a name={this.props.chapter_number}>
              <span className="chapter-number">
                {this.props.chapter_number}
              </span>
              <span className="chapter-title">{this.props.chapter_title}</span>
            </a>
          </h1>
        )}
        {header}
        <div className="cos-services">{cosServices}</div>
      </div>
    );
  }
}

export default withRouter(CosChapter);
