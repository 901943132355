import React from "react";
import axios from "axios";
import Orderline from "./Orderline";
import BackToTheTopButton from "../extracted_components/BackToTheTopButton";
import { BrowserRouter, Router, Route, Link } from "react-router-dom";
import "../../css/Orderline.css";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import NotificationsManager from "../extracted_components/NotificationsManager";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { exportToPDF } from "../../src/utils/exportToPDF";
import { exportToExcel } from "../../src/utils/exportToExcel";


class Orderlines extends NotificationsManager {
  constructor(props) {
    super(props);
    this.notificationDOMRef = React.createRef();
    this.state = {
      group_by: "status",
      order_lines: [],
      groups: [],
      grouped_order_lines: {},
      customers: [],
      loading: true,
      selectedCustomer: null,
      selectedServices: null,
      selectedTargets: null,
      selectedStatuses: null,
      isEsad: false,
      showSuccessAssignAlert: false,
      accreditations: [],
      esadAlertColor: null,
      esadAlertText: null,
      esadAlertTitle: null,
      current_user: null,
      orderlines_currency: null,
      //user who can see financial information
      canSeePrice: null,
      // toggle show / hide price
      togglePriceInfo: false
    };
  }

  componentDidMount() {
    this.setState({ isEsad: this.props.isEsad });
    this.fetchOrderlines();
    if (this.props.isEsad) {
      this.fetchAccreditations();
      //for esad, default group_by target
      this.setState({ group_by: "target" });
    }
  }

  fetchOrderlines = () => {
    //TODO change the url to get the esad order_lines api
    const url = this.props.isEsad
      ? "/order_lines.json?type=esad"
      : "/order_lines.json";
    axios.get(url).then((response) => {
      const {response: res, user} = response.data
      const orderlines_currency = res.length > 0 ? res[0].currency : null;
      this.setState(
        {
          order_lines: res,
          customers: this.findCustomers(res),
          orderlines_currency,
          current_user: user?.table,
          canSeePrice: user?.table?.can_see_financial_info
        },
        this.groupAndFilterOrderLines
      );
    });
  };

  fetchAccreditations = () => {
    axios.get("/accreditations.json").then((response) => {
      if (response.data.length > 0) {
        const withLastNameAccred = response.data.filter(
          (accred) => accred.last_name
        );
        const sortByLastname = withLastNameAccred.sort(function (
          accredA,
          accredB
        ) {
          return accredA.last_name.localeCompare(accredB.last_name);
        });
        this.setState({ accreditations: sortByLastname });
      }
    });
  };

  findCustomers = (orderlines) => {
    let customers = [];
    orderlines.map((orderline) => {
      if (customers.indexOf(orderline.customerCode) == -1) {
        customers.push(orderline.customerCode);
      }
    });
    return customers;
  };

  singleMrlName = () => {
    if (this.state.customers.length != 1) {
      return null;
    } else {
      return " – " + this.state.customers[0];
    }
  };

  onChangeCustomer = (event) => {
    this.setState({
      selectedCustomer: event.target.value,
      loading: true,
    });
    setTimeout(this.groupAndFilterOrderLines, 1);
  };

  onChangeService = (value) => {
    this.setState({
      selectedServices: value,
      loading: true,
    });
    setTimeout(this.groupAndFilterOrderLines, 1);
  };

  onChangeTarget = (value) => {
    this.setState({
      selectedTargets: value,
      loading: true,
    });
    setTimeout(this.groupAndFilterOrderLines, 1);
  };

  onChangeStatus = (value) => {
    this.setState({
      selectedStatuses: value,
      loading: true,
    });
    setTimeout(this.groupAndFilterOrderLines, 1);
  };

  formatNumber = (number) => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  groupAndFilterOrderLines = () => {
    let visible_order_lines = this.state.order_lines;
    let order_lines_without_target_filter = visible_order_lines;
    let order_lines_without_services_filter = visible_order_lines;
    let order_lines_without_status_filter = visible_order_lines;

    // Filtering

    // If the contact is associated to more than one customer and hasn't made a selection, display nothing
    if (
      this.state.customers.length > 1 &&
      this.state.selectedCustomer == null
    ) {
      visible_order_lines = [];
    } else {
      // If the contact has selected a customer, filter the order lines
      if (
        this.state.customers.length > 1 &&
        this.state.selectedCustomer != null
      ) {
        visible_order_lines = visible_order_lines.filter(
          (orderline) => orderline.customerCode == this.state.selectedCustomer
        );
      }
    }

    order_lines_without_target_filter = visible_order_lines;
    order_lines_without_services_filter = visible_order_lines;
    order_lines_without_status_filter = visible_order_lines;

    // Filter by services if needed
    if (
      this.state.selectedServices !== null &&
      this.state.selectedServices.length > 0
    ) {
      let serviceCodes = this.state.selectedServices.map(
        (selectedService) => selectedService.value
      );
      visible_order_lines = visible_order_lines.filter(
        (orderline) => serviceCodes.indexOf(orderline.serviceCode) != -1
      );
      order_lines_without_target_filter =
        order_lines_without_target_filter.filter(
          (orderline) => serviceCodes.indexOf(orderline.serviceCode) != -1
        );
      order_lines_without_status_filter =
        order_lines_without_status_filter.filter(
          (orderline) => serviceCodes.indexOf(orderline.serviceCode) != -1
        );
    }

    // Filter by targets if needed
    if (
      this.state.selectedTargets !== null &&
      this.state.selectedTargets.length > 0
    ) {
      let targets = this.state.selectedTargets.map(
        (selectedTarget) => selectedTarget.value
      );
      visible_order_lines = visible_order_lines.filter(
        (orderline) => targets.indexOf(orderline.target) != -1
      );
      order_lines_without_services_filter =
        order_lines_without_services_filter.filter(
          (orderline) => targets.indexOf(orderline.target) != -1
        );
      order_lines_without_status_filter =
        order_lines_without_status_filter.filter(
          (orderline) => targets.indexOf(orderline.target) != -1
        );
    }

    // Filter by status if needed
    if (
      this.state.selectedStatuses !== null &&
      this.state.selectedStatuses.length > 0
    ) {
      let statuses = this.state.selectedStatuses.map(
        (selectedStatus) => selectedStatus.value
      );

      if (statuses.indexOf("CANCELLED") != -1) {
        statuses.push("CNC");
        statuses.push("CN$");
      }
      visible_order_lines = visible_order_lines.filter(
        (orderline) => statuses.indexOf(orderline.publicStatus) != -1
      );
      order_lines_without_services_filter =
        order_lines_without_services_filter.filter(
          (orderline) => statuses.indexOf(orderline.publicStatus) != -1
        );
      order_lines_without_target_filter =
        order_lines_without_target_filter.filter(
          (orderline) => statuses.indexOf(orderline.publicStatus) != -1
        );
    }

    // Grouping
    let grouped_order_lines = [];
    let groups = [];

    if (this.state.group_by == "status") {
      grouped_order_lines = visible_order_lines
        .reduce(function (r, a) {
          let publicStatus = a.publicStatus;
          if (publicStatus == "CNC" || publicStatus == "CN$") {
            publicStatus = "CANCELLED";
          }

          r[publicStatus] = r[publicStatus] || [];
          r[publicStatus].push(a);
          return r;
        }, {});

      let groupKeys = Object.keys(grouped_order_lines);

      if (groupKeys.indexOf("NEW") != -1) {
        groups.push({ key: "NEW", title: "New Orders", total_price_by_group: grouped_order_lines["NEW"].reduce((sum, orderline) => sum + orderline.calculate_price, 0) });
      }
      if (groupKeys.indexOf("REQ") != -1) {
        groups.push({ key: "REQ", title: "Orders in progress", total_price_by_group: grouped_order_lines["REQ"].reduce((sum, orderline) => sum + orderline.calculate_price, 0) });
      }
      if (groupKeys.indexOf("CNF") != -1) {
        groups.push({ key: "CNF", title: "Confirmed orders", total_price_by_group: grouped_order_lines["CNF"].reduce((sum, orderline) => sum + orderline.calculate_price, 0) });
      }
      if (groupKeys.indexOf("N/A") != -1) {
        groups.push({ key: "N/A", title: "Not available", total_price_by_group: grouped_order_lines["N/A"].reduce((sum, orderline) => sum + orderline.calculate_price, 0) });
      }
      if (groupKeys.indexOf("CANCELLED") != -1) {
        groups.push({ key: "CANCELLED", title: "Cancelled orders", total_price_by_group: grouped_order_lines["CANCELLED"].reduce((sum, orderline) => sum + orderline.calculate_price, 0) });
      }
    }

    if (this.state.group_by == "target") {
      grouped_order_lines = visible_order_lines.reduce(function (r, a) {
        r[a.target] = r[a.target] || [];
        r[a.target].push(a);
        return r;
      }, {});

      groups = Object.keys(grouped_order_lines).map((groupKey) => {
        let targetWithoutKickOff = groupKey.split("-");
        // Remove the kick off from the target name
        targetWithoutKickOff.splice(3, 1);
        return { key: groupKey, title: targetWithoutKickOff.join("-"), total_price_by_group: grouped_order_lines[groupKey].reduce((sum, orderline) => sum + orderline.calculate_price, 0) };
      });
    }

    if (this.state.group_by == "service") {
      grouped_order_lines = visible_order_lines
        .reduce(function (r, a) {
          let service = a.serviceCode + " - " + a.serviceDescription;
          r[service] = r[service] || [];
          r[service].push(a);
          return r;
        }, {});

      groups = Object.keys(grouped_order_lines)
        .map((groupKey) => {
          let serviceCode = groupKey.split(" - ")[0];
          return { key: groupKey, title: groupKey, serviceCode: serviceCode, total_price_by_group: grouped_order_lines[groupKey].reduce((sum, orderline) => sum + orderline.calculate_price, 0) };
        })
        .sort((a, b) => {
          return a.serviceCode.localeCompare(b.serviceCode);
        });
    }
    this.setState({
      visible_order_lines: visible_order_lines,
      grouped_order_lines: grouped_order_lines,
      order_lines_without_target_filter: order_lines_without_target_filter,
      order_lines_without_services_filter: order_lines_without_services_filter,
      order_lines_without_status_filter: order_lines_without_status_filter,
      groups: groups,
      loading: false,
    });
  };

  customerSelect = () => {
    if (this.state.customers.length < 2) {
      return null;
    }

    let customerOptions = this.state.customers.map((customer) => {
      return (
        <option
          key={customer}
          value={customer}
          selected={this.state.selectedCustomer == customer ? "selected" : null}
        >
          {customer}
        </option>
      );
    });

    return (
      <select
        className="customer-filter-select form-control col-4"
        onChange={(event) => {
          this.onChangeCustomer(event);
        }}
        value={this.state.selectedCustomer || ""}
      >
        <option value="">Select a {settings["customer_name"]}</option>
        {customerOptions}
      </select>
    );
  };

  groupChanged = (event) => {
    event.stopPropagation();
    let label = event.target;
    if (label.getElementsByTagName("input").length == 0) {
      return;
    }

    let value = label.getElementsByTagName("input")[0].getAttribute("value");

    if (value !== null) {
      this.setState({ group_by: value });
      setTimeout(this.groupAndFilterOrderLines, 1);
    }
  };

  groupSelector = () => {
    const groupBy = (
      <label
        className={
          this.state.group_by == "status"
            ? "btn btn-primary active"
            : "btn btn-primary"
        }
        onClick={this.groupChanged}
      >
        <input
          type="radio"
          name="options"
          id="status"
          value="status"
          autoComplete="off"
          checked={this.state.group_by == "status"}
          onChange={this.groupChanged}
        />{" "}
        Group by Status
      </label>
    );

    const shouldTargetBeActive = () => {
      if (this.state.isEsad && this.state.group_by == "status") {
        return "btn btn-primary active";
      } else if (this.state.group_by == "target") {
        return "btn btn-primary active";
      } else {
        return "btn btn-primary";
      }
    };

    return (
      <div
        className="orders_group_selector btn-group btn-group-toggle"
        data-toggle="buttons"
        onChange={this.groupChanged}
      >
        {!this.state.isEsad && groupBy}
        <label className={shouldTargetBeActive()} onClick={this.groupChanged}>
          <input
            type="radio"
            name="options"
            id="target"
            value="target"
            autoComplete="off"
            checked={this.state.group_by == "target"}
            onChange={this.groupChanged}
          />{" "}
          {this.state.isEsad && "Group"} by Match/Venue
        </label>
        <label
          className={
            this.state.group_by == "service"
              ? "btn btn-primary active"
              : "btn btn-primary"
          }
          onClick={this.groupChanged}
        >
          <input
            type="radio"
            name="options"
            id="service"
            value="service"
            autoComplete="off"
            checked={this.state.group_by == "service"}
            onChange={this.groupChanged}
          />{" "}
          by Service
        </label>
      </div>
    );
  };

  serviceFilter = () => {
    let visible_order_lines =
      this.state.order_lines_without_services_filter || [];

    let services = visible_order_lines.reduce(function (r, a) {
      let service = a.serviceCode + " - " + a.serviceDescription;
      let option = { value: a.serviceCode, label: service };
      r[a.serviceCode] = option;
      return r;
    }, {});

    return (
      <Select
        options={Object.values(services).sort((a, b) => {
          return b.value < a.value ? 1 : -1;
        })}
        value={this.state.selectedServices}
        onChange={(event) => {
          this.onChangeService(event);
        }}
        isMulti={true}
        className="services-filter-select"
        placeholder="Filter by Service"
      />
    );
  };

  targetFilter = () => {
    let visible_order_lines =
      this.state.order_lines_without_target_filter || [];

    let targets = visible_order_lines.reduce(function (r, a) {
      let targetWithoutKickOff = a.target.split("-");
      // Remove the kick off from the target name
      targetWithoutKickOff.splice(3, 1);
      let option = { value: a.target, label: targetWithoutKickOff.join("-") };
      r[a.target] = option;
      return r;
    }, {});

    return (
      <Select
        options={Object.values(targets)}
        value={this.state.selectedTargets}
        onChange={(event) => {
          this.onChangeTarget(event);
        }}
        isMulti={true}
        className="targets-filter-select"
        placeholder="Filter by Match/Venue"
      />
    );
  };

  statusFilter = () => {
    let visible_order_lines =
      this.state.order_lines_without_status_filter || [];

    let statuses = visible_order_lines.reduce(function (r, a) {
      let value = a.publicStatus;
      let label = a.publicStatus;

      if (value == "CNC" || value == "CN$") {
        value = "CANCELLED";
      }

      if (value == "NEW") {
        label = "New Orders";
      }
      if (value == "REQ") {
        label = "Orders in progress";
      }
      if (value == "CNF") {
        label = "Confirmed orders";
      }
      if (value == "N/A") {
        label = "Not available";
      }
      if (value == "CANCELLED") {
        label = "Cancelled orders";
      }

      let option = { value: value, label: label };
      r[value] = option;
      return r;
    }, {});

    let statusesOptions = [];
    if (Object.keys(statuses).indexOf("NEW") != -1) {
      statusesOptions.push(statuses["NEW"]);
    }
    if (Object.keys(statuses).indexOf("REQ") != -1) {
      statusesOptions.push(statuses["REQ"]);
    }
    if (Object.keys(statuses).indexOf("CNF") != -1) {
      statusesOptions.push(statuses["CNF"]);
    }
    if (Object.keys(statuses).indexOf("N/A") != -1) {
      statusesOptions.push(statuses["N/A"]);
    }
    if (Object.keys(statuses).indexOf("CANCELLED") != -1) {
      statusesOptions.push(statuses["CANCELLED"]);
    }

    return (
      <Select
        options={statusesOptions}
        value={this.state.selectedStatuses}
        onChange={(event) => {
          this.onChangeStatus(event);
        }}
        isMulti={true}
        className="statuses-filter-select"
        placeholder="Filter by Status"
      />
    );
  };

  groupHeaderClicked = (groupKey, event) => {
    let groups = this.state.groups.map((group) => {
      if (group.key == groupKey) {
        if (group.collapsed === null) {
          group.collapsed = true;
        } else {
          group.collapsed = !group.collapsed;
        }
      }
      return group;
    });

    this.setState({ groups: groups });
  };

  editSuccess = () => {
    this.addNotification(
      this.notificationDOMRef,
      "Notice",
      "Your request has been updated",
      "success"
    );
  };

  errorMessage = (message) => {
    this.addNotification(
      this.notificationDOMRef,
      "Error",
      message,
      "danger",
      30000
    );
  }

  cancelSuccess = () => {
    this.addNotification(
      this.notificationDOMRef,
      "Notice",
      "Your request has been cancelled",
      "success"
    );
  };

  filtersDescription = () => {
    let filters = [];

    if (
      this.state.selectedStatuses !== null &&
      this.state.selectedStatuses.length > 0
    ) {
      filters.push(
        <li key="status_filter">
          Status:{" "}
          <ul>
            {this.state.selectedStatuses.map((opt, index) => (
              <li key={index}>{opt.label}</li>
            ))}
          </ul>
        </li>
      );
    }

    if (
      this.state.selectedTargets !== null &&
      this.state.selectedTargets.length > 0
    ) {
      filters.push(
        <li key="status_target">
          Match/Venue:{" "}
          <ul>
            {this.state.selectedTargets.map((opt, index) => (
              <li key={index}>{opt.label}</li>
            ))}
          </ul>
        </li>
      );
    }

    if (
      this.state.selectedServices !== null &&
      this.state.selectedServices.length > 0
    ) {
      filters.push(
        <li key="status_service">
          Service:{" "}
          <ul>
            {this.state.selectedServices.map((opt, index) => (
              <li key={index}>{opt.label}</li>
            ))}
          </ul>
        </li>
      );
    }

    return <ul>{filters}</ul>;
  };

  esadAssignementAlert = () => {
    return (
      <Alert
        variant={this.state.esadAlertColor}
        onClose={() => this.setState({ showSuccessAssignAlert: false })}
        dismissible
        style={{
          position: "fixed",
          bottom: 0,
          left: "10%",
          right: "10%",
          zIndex: 999,
        }}
      >
        {this.state.esadAlertTitle && (
          <Alert.Heading>{this.state.esadAlertTitle}</Alert.Heading>
        )}
        <p>{this.state.esadAlertText}</p>
      </Alert>
    );
  };

  setEsadAlertColorAndText = (color, text, title = null) => {
    this.setState({
      showSuccessAssignAlert: true,
      esadAlertColor: color,
      esadAlertText: text,
      esadAlertTitle: title,
    });
  };

  updateOrderline = (newOrderline) => {
    this.setState((prevState) => {
      const filteredOrderlines = prevState.order_lines.map((orderline) => {
        if (orderline.id !== newOrderline.id) {
          return orderline;
        } else {
          return newOrderline;
        }
      });
      return {
        order_lines: filteredOrderlines,
      };
    });
    this.groupAndFilterOrderLines();
  };

  priceHeader = (group) => {
    if (this.state.canSeePrice && this.state.togglePriceInfo) {
      return(
        <>
          <td colSpan={1} className="text-nowrap">Price per unit</td>
          <td colSpan={1} className="text-nowrap">Discount</td>
          <td colSpan={1} className="text-nowrap">Total: {this.state.orderlines_currency} {this.formatNumber(group.total_price_by_group)}</td>
        </>
      )
    } else {
      return null
    }
  }

  handleToogleChange = () => {
    this.setState({
      ...this.state,
      togglePriceInfo: !this.state.togglePriceInfo
    })
  }
  togglePriceElement = () => {
    if (this.state.canSeePrice) {
      return(
        <div className="d-flex align-items-center">
          <Toggle
          id="toggle-price"
          className="mr-2"
          defaultChecked={this.state.togglePriceInfo}
          onChange={this.handleToogleChange} />
          <span className="font-weight-light">{this.state.togglePriceInfo ? "Hide Price" : "Show Price"}</span>
        </div>
      )
    }
  }

  displayTotalSum = () => {
    if(this.state.canSeePrice && this.state.togglePriceInfo) {
      const { groups } = this.state;
      const totalSum = groups.reduce((sum, group) => sum + group.total_price_by_group, 0);
      return (
        <h5 className="ml-3 mb-0 font-weight-bold" id="total-sum">Total: {this.state.orderlines_currency} {this.formatNumber(totalSum)}</h5>
      )
    }else{
      return null;
    }
  }

  exportExcelButton = () => {
    if(this.state.canSeePrice && this.state.togglePriceInfo) {
      return(
        <button className="btn btn-sm btn-success mr-2" onClick={()=> exportToExcel()}>Export to Excel</button>
      )
    }
  }

  render() {
    let getInstructions = this.state.isEsad ? (
      <div>
        <li>The e-BBT process concerns positions on the field of play or those located in the competition area as listed below. The other booked positions are still managed with physical BBTs.</li>
        <li>At each match, for each confirmed service that is subject to the e-BBT process, please select the name of the person that is supposed to be working at this position.
            In the dropdown menu below, you will find all the accredited staff from your organization. You can search through this list by typing part of the name of your colleagues.</li>
        <li>There is a limit in the quantity of people that you can assign to each confirmed service, please refer to the e-BBT Guidelines for additional information.<br/>
            For any questions, please contact <a href="mailto:booking@hbs.tv">booking@hbs.tv</a>.</li>
      </div>) : (
      <li>
        You can only edit or cancel an Order if its Status is NEW. Please
        contact Booking if you wish to amend an Order whose Status is not NEW.
      </li>
    );

    let instructions = (
      <div className="alert alert-primary instructions" role="alert">
        <ul>
          {getInstructions}
          <li>
            <b>{settings["timing_instructions"]}</b>
          </li>
        </ul>
      </div>
    );

    let orderlineTitle = (
      <div className="order-lines-title">
        <div className={this.state.isEsad ? 'order-title-esad' : 'order-title'}>
          <div className="d-flex align-items-center">
            <h1>
              {this.state.isEsad ? "Broadcast Booking Ticket" : "Orders"}
              {this.singleMrlName()}
            </h1>
            {this.customerSelect()}
            {!this.state.isEsad && this.displayTotalSum()}
          </div>
          {!this.state.isEsad && this.togglePriceElement()}
        </div>
        {!this.state.isEsad && (
          <div className="button-block do-not-print">
            <Link className="btn btn-primary btn-lg" to={"/orders/new"}>
              <i className="fa fa-plus"></i>&nbsp; Create a new order
            </Link>
            <div className="mt-2">
              {this.exportExcelButton()}
              <button className="btn btn-sm btn-outline-info" onClick={()=> exportToPDF()}>Export to PDF</button>
            </div>
          </div>
        )}
      </div>
    );

    let table = (
      <div className="orderlines-list-table">
        <table className="table" id="orderlines-orders-table">
          {this.state.groups.map((group) => {
            return (
              <tbody key={group.key}>
                {!this.state.isEsad &&
                  (this.state.groups[0].key == group.key ? null : (
                    <tr className="group_header_spacer">
                      <td colSpan={7}></td>
                    </tr>
                  ))}
                {
                  <tr
                    className="group_header"
                    onClick={(event) =>
                      this.groupHeaderClicked(group.key, event)
                    }
                  >
                    <td colSpan={6}>{group.title}</td>
                    {!this.state.isEsad && this.priceHeader(group)}
                    <td className="angle-icon">
                      {group.collapsed == true ? (
                        <i className="fa fa-angle-up"></i>
                      ) : (
                        <i className="fa fa-angle-down"></i>
                      )}
                    </td>
                  </tr>
                }
                {group.collapsed == true
                  ? null
                  : this.state.grouped_order_lines[group.key].map(
                      (orderline) => {
                        return (
                          <Orderline
                            {...orderline}
                            displayPrices={false}
                            editable={true}
                            key={orderline.id}
                            reloadFn={this.fetchOrderlines}
                            editSuccessFn={this.editSuccess}
                            cancelSuccessFn={this.cancelSuccess}
                            errorMessage={this.errorMessage}
                            //esad props
                            isEsad={this.state.isEsad}
                            accreditations={
                              this.state.isEsad && this.state.accreditations
                            }
                            showEsadAssignAlert={this.setEsadAlertColorAndText}
                            updateOrderline={this.updateOrderline}
                            canSeePrice={this.state.canSeePrice}
                            togglePriceInfo={this.state.togglePriceInfo}
                          />
                        );
                      }
                    )}
              </tbody>
            );
          })}
        </table>
        {this.state.showSuccessAssignAlert && this.esadAssignementAlert()}
        <BackToTheTopButton />
      </div>
    );

    return (
      <div id="orderlines-orders">
        <NotificationsManager notificationDOMRef={this.notificationDOMRef} />
        {orderlineTitle}
        {instructions}
        <div className="orderlines-filters do-not-print">
          {this.groupSelector()}
          {!this.state.isEsad && this.statusFilter()}
          {this.targetFilter()}
          {this.serviceFilter()}
        </div>
        <div className="filters-to-print">{this.filtersDescription()}</div>
        <div className="orderlines-list">
          {this.state.loading ? "Loading..." : table}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.order.customer_id,
  };
};

export default connect(mapStateToProps, null)(Orderlines);
