import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportToPDF = () => {
  const table = document.querySelector("#orderlines-orders-table");
  const totalSum = document.querySelector("#total-sum");
  const title = document.querySelector("#event-title");
  const tbodies = table.querySelectorAll("tbody");

  const pdfSections = [];

  const columnWidths = {};

  const percentToMm = (percent) => {
    const pageWidth = 297; // A4 width in mm (landscape orientation)
    return (percent / 100) * pageWidth;
  };

  // Extract headers and data
  tbodies.forEach((tbody) => {
    const rows = tbody.querySelectorAll("tr");
    let sectionHeader = [];
    const sectionData = [];

    // Process data rows first
    rows.forEach((row) => {
      if (
        !row.classList.contains("group_header") &&
        !row.classList.contains("group_header_spacer")
      ) {
        const cells = row.querySelectorAll("td");
        const rowData = Array.from(cells).map((cell) =>
          cell.textContent.trim()
        );
        sectionData.push(rowData);
      }
    });

    // Process header rows after data rows
    rows.forEach((row) => {
      if (row.classList.contains("group_header")) {
        const cells = row.querySelectorAll("td");
        const originalHeader = Array.from(cells).map((cell) =>
          cell.textContent.trim()
        );

        if (sectionData.length > 0) {
          const firstDataRowLength = sectionData[0].length;
          if (firstDataRowLength < 7) {
            sectionHeader = [{ content: originalHeader[0], colSpan: 4 }];
            // cell width assignment
            Object.assign(columnWidths, {
              0: { cellWidth: percentToMm(20) },
              1: { cellWidth: percentToMm(55) },
              2: { cellWidth: percentToMm(10) },
              3: { cellWidth: percentToMm(5) },
            });
          }
          if (firstDataRowLength > 7) {
            sectionHeader = [
              { content: originalHeader[0], colSpan: 2 },
              "",
              "",
              originalHeader[1],
              originalHeader[2],
              originalHeader[3],
            ];
            Object.assign(columnWidths, {
              0: { cellWidth: percentToMm(15) },
              1: { cellWidth: percentToMm(35) },
              2: { cellWidth: percentToMm(5) },
              3: { cellWidth: percentToMm(5) },
              4: { cellWidth: percentToMm(11) },
              5: { cellWidth: percentToMm(11) },
              6: { cellWidth: percentToMm(11) },
            });
          }
        } else {
          sectionHeader = [{ content: originalHeader[0], colSpan: 4 }];
        }
      }
    });

    if (sectionHeader.length > 0 && sectionData.length > 0) {
      pdfSections.push({ header: sectionHeader, data: sectionData });
    }
  });

  // Initialize jsPDF
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  let startY = 10; // Initial Y-position for the first table

  // Add PDF title
  doc.setFontSize(24);
  doc.text(title.textContent.trim(), 150, 20, null, null, "center");
  startY += 20;

  pdfSections.forEach((section, index) => {
    doc.autoTable({
      head: [section.header],
      body: section.data,
      startY, // Start position for this section
      margin: { top: 20 },
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: 255,
        fontStyle: "bold",
      },
      theme: "grid",
      rowPageBreak: "avoid",
      columnStyles: columnWidths,
      pageBreak: "auto",
      didDrawPage: function (data) {
        // Reset the start position for a new page
        startY = 20;
      },
      willDrawCell: function (data) {
        const pageHeight = doc.internal.pageSize.height;
        const safeMargin = 40;

        // Check if adding this header would exceed the page height
        if (data.section === "head") {
          const headerText = data.row.raw
            .map((cell) => cell.content || cell)
            .join(" ");
          const headerDimensions = doc.getTextDimensions(headerText);
          const headerHeight = headerDimensions.h + 15;
          if (data.cursor.y + headerHeight > pageHeight - safeMargin) {
            doc.addPage(); // Add a new page
            data.cursor.y = 20; // Reset the Y position for the new page
            doc.autoTable({
              head: [
                section.header.map((headerCell) => ({
                  ...headerCell,
                  colSpan: headerCell.colSpan || 1,
                })),
              ],
              body: [],
              startY: data.cursor.y,
              margin: { top: 20 },
              styles: { cellPadding: 2, fontSize: 10 },
              headStyles: {
                fillColor: [41, 128, 185],
                textColor: 255,
                fontStyle: "bold",
              },
              theme: "grid",
              rowPageBreak: "avoid",
              columnStyles: columnWidths,
            });
            // Prevent duplicate rendering of this header
            return false;
          }
        }
      },
    });

    // Update startY for the next section
    startY = doc.lastAutoTable.finalY + 10; // Add spacing below the last table
  });

  if (totalSum) {
    const totalSumValue = totalSum.textContent.trim();

    // Add footer
    doc.autoTable({
      body: [[totalSumValue]],
      startY: startY + 10, // Position below the last table
      margin: { left: 20, right: 20 },
      styles: { fontSize: 12, fontStyle: "bold", halign: "right" },
      theme: "plain",
    });
  }

  // Save the PDF
  doc.save("orders.pdf");
};
