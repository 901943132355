import * as XLSX from "xlsx-js-style";

export const exportToExcel = () => {
  const table = document.querySelector("#orderlines-orders-table");
  const totalSum = document.querySelector("#total-sum");
  const title = document.querySelector("#event-title");
  const tbodies = table.querySelectorAll("tbody");

  const totalText = totalSum.textContent.trim();

  // currencies
  const CURRENCY_SYMBOLS = '€$£¥₹₽₩₪₱₿';
  const currencyRegex = new RegExp(`[${CURRENCY_SYMBOLS}]`);

  const convertPriceToNumber = (priceString) => {
    return parseFloat(priceString.replace(currencyRegex, '').replace(/,/g, ''))
  };

  function extractCurrencySymbol(text) {
    const match = text.match(currencyRegex);
    return match ? match[0] : null;
  }

  const currency = extractCurrencySymbol(totalText);

  const excelData = [];

  // Add title
  excelData.push([
    {
      v: title.textContent.trim(),
      s: {
        font: { bold: true, sz: 30, color: { rgb: "000000" } },
        alignment: { horizontal: "center", wrapText: true },
      },
    },
  ]);

  // Add empty rows after title
  excelData.push([], []);

  // Add Header row
  const header = [
    "Target",
    "Service",
    "Status",
    "Qty",
    "Price per unit",
    "Discount",
    "Total",
  ].map((cell) => ({
    v: cell,
    s: {
      font: { bold: true, sz: 15, color: { rgb: "FFFFFF" } },
      fill: { fgColor: { rgb: "2980B9" } },
      alignment: { horizontal: "left", vertical: "middle" },
    },
  }));
  excelData.push(header);

  // Extract table data
  tbodies.forEach((tbody) => {
    const rows = tbody.querySelectorAll("tr");
    const sectionData = [];

    // Process data rows
    rows.forEach((row) => {
      if (
        !row.classList.contains("group_header") &&
        !row.classList.contains("group_header_spacer")
      ) {
        const cells = row.querySelectorAll("td");
        const rowData = Array.from(cells)
          .filter((cell) => !cell.classList.contains("do-not-print"))
          .map((cell) => {
            // add line break for each div inside service/target td
            if (
              cell.classList.contains("service") ||
              cell.classList.contains("target")
            ) {
              const divs = cell.querySelectorAll("div");
              if (divs.length > 1) {
                return {
                  v: Array.from(divs)
                    .map((div) => div.textContent.trim())
                    .join("\n"),
                  s: {
                    font: { sz: 13 },
                    alignment: {
                      wrapText: true,
                      horizontal: "left",
                      vertical: "middle",
                    },
                  },
                };
              }
            }
            // format price to number
            if (
              cell.classList.contains("unit-price") ||
              cell.classList.contains("total-price") ||
              cell.classList.contains("discount")
            ) {
              return {
                v: convertPriceToNumber(cell.textContent.trim()),
                t: "n",
                z: currency ? `${currency}#,##0.00` : "#,##0.00",
                s: {
                  font: { sz: 13 },
                  alignment: {
                    wrapText: true,
                    horizontal: "left",
                    vertical: "middle",
                  },
                },
              };
            }
            return {
              v: cell.textContent.trim(),
              s: {
                font: { sz: 13 },
                alignment: {
                  wrapText: true,
                  horizontal: "left",
                  vertical: "middle",
                },
              },
            };
          });
        sectionData.push(rowData);
      }
    });

    excelData.push(...sectionData);
  });

  // Add empty rows after table data before total sum
  excelData.push([], []);

  // Add total sum
  if (totalSum) {
    excelData.push([{ v: totalText, s: { font: { bold: true, sz: 20 } } }]);
  }

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(excelData);

  ws["!merges"] = ws["!merges"] || [];

  ws["!merges"].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }); // Merge title cells

  // Set title height
  ws["!rows"] = [{ hpt: 30 }];

  // Set column widths
  ws["!cols"] = [
    { wch: 30 },
    { wch: 100 },
    { wch: 10 },
    { wch: 10 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
  ];

  XLSX.utils.book_append_sheet(wb, ws, "Orders");

  XLSX.writeFile(wb, "orders.xlsx", { cellStyles: true });
};
