import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import DateTime from "react-datetime";
import "../../css/Orderline.css";
import { inputIsValid } from "../extracted_components/ValidationsServices";
import { fetchOrderLine } from "../../apis/ApiCall";
import NotificationsManager from "../extracted_components/NotificationsManager";
import ReactNotification from "react-notifications-component";
import Select from "react-select";
import { ThemeConsumer } from "styled-components";
import Button from "react-bootstrap/Button";

class Orderline extends NotificationsManager {
  constructor(props) {
    super(props);
    this.notificationDOMRef = React.createRef();
    this.state = {
      orderline: this.props,
      slot: null,
      showModal: false,
      newStartTime: null,
      newEndTime: null,
      newQuantity: null,
      showEsadModal: false,
      showLimitAssignementAlert: false,
      selectedColor: null,
      selectedAccreIds: [],
    };
  }

  cancelOrderline = (id) => {
    axios.put("/order_lines/" + id + "/cancel").then((response) => {
      // https://gitlab.ekohe.com/ekohe/hbs/obi/-/issues/187
      if(response.data.success === false && this.props.errorMessage) {
        this.props.errorMessage(response.data.message)
        return false
      }
      // After 1 second, reload the order lines
      setTimeout(this.props.reloadFn, 1000);
      if (this.props.cancelSuccessFn) {
        this.props.cancelSuccessFn();
      }
      this.setState({
        orderline: response.data,
        newStartTime: response.data.startTime,
        newEndTime: response.data.endTime,
        newQuantity: response.data.quantity,
      });
    });
  };

  editOrderline = (id) => {
    if (
      this.state.orderline.should_prompt_slot_number === true &&
      this.state.slot === null
    ) {
      alert("Please select a slot.");
      return false;
    }

    if (this.state.orderline !== null) {
      axios
        .put("/order_lines/" + id + ".json", {
          order_line: {
            ...(this.state.slot !== null || this.state.orderline.forMn
              ? {}
              : { requested_quantity: this.state.newQuantity }),
            ...(this.state.orderline.forMn
              ? { requested_start_time: this.state.newStartTime }
              : {}),
            ...(this.state.orderline.forMn
              ? { requested_end_time: this.state.newEndTime }
              : {}),
            ...(this.state.slot !== null
              ? { service_pre_post_tag: this.state.slot }
              : {}),
          },
        })
        .then((response) => {
          // https://gitlab.ekohe.com/ekohe/hbs/obi/-/issues/187
          if(response.data.success === false && this.props.errorMessage) {
            this.props.errorMessage(response.data.message)
            return false
          }
          if (
            response.data.isQty1 &&
            this.state.newQuantity != response.data.quantity &&
            this.props.reloadFn
          ) {
            this.props.reloadFn();
          }
          if (this.props.editSuccessFn) {
            this.props.editSuccessFn();
          }
          this.setState({
            orderline: response.data,
            newStartTime: response.data.startTime,
            newEndTime: response.data.endTime,
            newQuantity: response.data.quantity,
          });
        });
    }
    return true;
  };

  showModalHandler = () => {
    fetchOrderLine(this, this.props.id);

    this.setState({
      showModal: true,
    });

    return true;
  };

  closeModalHandler = () => {
    this.setState({
      showModal: false,
    });
    return true;
  };

  onChangeQuantity = (event) => {
    inputIsValid(this.state.orderline, event.target.value, "quantity", this);
    this.setState({ newQuantity: event.target.value });
  };

  onChangeStartTime = (event) => {
    if (typeof event.format == "function") {
      this.setState({ newStartTime: event.format("H:mm") });
    } else {
      inputIsValid(this.state.orderline, event, "start_time", this);
    }
  };

  onChangeEndTime = (event) => {
    if (typeof event.format == "function") {
      this.setState({ newEndTime: event.format("H:mm") });
    } else {
      inputIsValid(this.state.orderline, event, "end_time", this);
    }
  };

  onChangeSlot = (event) => {
    this.setState({ slot: event.target.value });
  };

  editOrderlineIfValid = () => {
    if (this.state.orderline.isValid) {
      this.editOrderline(this.props.id) && this.closeModalHandler();
    } else {
      {
        this.addNotification(
          this.notificationDOMRef,
          "Error",
          "Input is not valid",
          "warning"
        );
      }
    }
  };

  editModal = () => {
    let editable = this.props.editable || false;

    if (editable == false || this.state.showModal != true) {
      return null;
    }

    // By default, display the quantity field
    let field = (
      <div className="mb-4">
        <label>Quantity</label>
        <div>
          <input
            onChange={(event) => {
              this.onChangeQuantity(event);
            }}
            value={this.state.newQuantity || ""}
            type="text"
          />
        </div>
      </div>
    );

    // If 'for_mn' is true, display the start / end time fields
    if (this.state.orderline.forMn == true) {
      field = (
        <div className="mb-4">
          <label>Start time/ End time (IBC local time)</label>
          <div>
            <DateTime
              onChange={(event) => {
                this.onChangeStartTime(event);
              }}
              dateFormat={false}
              timeFormat={"H:mm"}
              inputProps={{ placeholder: "Start Time" }}
              value={this.state.newStartTime || ""}
            />
            <DateTime
              onChange={(event) => {
                this.onChangeEndTime(event);
              }}
              dateFormat={false}
              timeFormat={"H:mm"}
              inputProps={{ placeholder: "End Time" }}
              value={this.state.newEndTime || ""}
            />
          </div>
        </div>
      );
    }

    if (this.state.orderline.should_prompt_slot_number === true) {
      let slotOptions = [<option value="">Select a slot</option>];

      for (var i = 1; i <= this.props.slot_count; i++) {
        slotOptions.push(<option value={i}>Slot {i}</option>);
      }

      field = (
        <div>
          <label>Slot</label>
          <div>
            <select
              onChange={(event) => {
                this.onChangeSlot(event);
              }}
              className="select-slot form-control"
              value={this.state.slot || ""}
            >
              {slotOptions}
            </select>
          </div>
        </div>
      );
    }

    let modalBody =
      this.state.orderline === null ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Modal.Header closeButton>
            <NotificationsManager
              notificationDOMRef={this.notificationDOMRef}
            />
            <Modal.Title>
              <h4>
                {this.state.orderline.target}
                <br />
                {this.state.orderline.serviceCode} -{" "}
                {this.state.orderline.serviceDescription}
                <br />
                Unit Price: {this.state.orderline.unitPrice}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{field}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              variant="secondary"
              onClick={() => this.closeModalHandler()}
            >
              Close without saving
            </button>
            <button
              className="btn btn-primary"
              variant="primary"
              onClick={(e) => this.editOrderlineIfValid()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      );

    return (
      <Modal
        size="lg"
        show={this.state.showModal}
        onHide={this.closeModalHandler}
      >
        {modalBody}
      </Modal>
    );
  };

  formatCurrency = (number, currency) => {
    if (number == null) {
      return "";
    }
    if (typeof number.toFixed != "function") {
      // Number is already formatted, don't do anything
      return number;
    }
    return (
      currency + " " + number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
  };

  singleOrMultiplePerson = () => {
    return this.state.orderline.accreditationAccessesLimitation > 1
      ? "people"
      : "person";
  };

  renderLimitAssignmentAlert = () => {
    return (
      <Alert variant="warning">
        <p>
          Broadcast Booking Ticket assignment is limited to{" "}
          {this.state.orderline.accreditationAccessesLimitation}{" "}
          {this.singleOrMultiplePerson()} maximum.
        </p>
      </Alert>
    );
  };
  openEsadModal = () => {
    this.setState({ showEsadModal: true });
  };
  closeEsadModal = (e) => {
    if (e != null) {
      e.stopPropagation();
    }
    this.setState({ showEsadModal: false });
    this.setState({ selectedColor: null });
  };
  handleAccreChange = (selectedOptions) => {
    const accreditationIds = selectedOptions.map((accred) => {
      return accred.value;
    });
    this.setState({ selectedAccreIds: accreditationIds });
    selectedOptions.length >
    this.state.orderline.accreditationAccessesLimitation
      ? this.setState({ showLimitAssignementAlert: true })
      : this.setState({ showLimitAssignementAlert: false });
  };

  assignAccreditation = async () => {
    try {
      const response = await axios.post("/assign_accreditations", {
        accreditation_ids: this.state.selectedAccreIds,
        order_line_id: this.props.id,
      });
      if (response.data.success === false) {
        const errorMessage = 'An error has occured during the Broadcast Booking Ticket assignement, please give another try or contact admin for support.'
        this.props.showEsadAssignAlert("danger", errorMessage, "Error!");
        this.setState({showEsadModal: false})
      } else {
        this.setState({ orderline: response.data, showEsadModal: false });
        this.props.updateOrderline(response.data);
        const accred_access_length =
          response.data.accreditation_accesses.length;
        accred_access_length === 0
          ? this.props.showEsadAssignAlert(
              "warning",
              "Broadcast Booking Ticket has been unassigned."
            )
          : this.props.showEsadAssignAlert(
              "success",
              "Broadcast Booking Ticket has been successfully assigned."
            );
      }
    } catch (err) {
      this.props.showEsadAssignAlert("danger", 'An error has occured, please try to refresh the page or contact admin for more support.', "Error!");
      this.setState({showEsadModal: false})
    }
  };

  submitEsad = (e) => {
    e.stopPropagation();
    this.setState({ selectedColor: null });
    if (
      this.state.selectedAccreIds.length >
      this.state.orderline.accreditationAccessesLimitation
    ) {
      this.setState({ showLimitAssignementAlert: true });
    } else {
      this.setState({ showLimitAssignementAlert: false });
      this.assignAccreditation();
    }
  };

  // Builds a string label from the accreditation data, of the format:
  // => First Name Last Name, Function, Organization, Note
  accreditationLabel = (accreditation) => {
    let name = [accreditation.first_name, accreditation.last_name].filter((obj) => obj).join(' ')
    let functionOrgAndNote = [accreditation.function_name, accreditation.organization_name, accreditation.accreditation_note].filter((obj) => obj ).join(', ')

    return [name, functionOrgAndNote].join(', ')
  };

  assignedAccredOption = () => {
    // Already assigned people
    const alreadyAssignedPeople =
      this.state.orderline.accreditation_accesses?.map((accred) => {
        const { accreditation } = accred;
        if (accreditation?.id) {
          return {
            value: accreditation.id,
            label: this.accreditationLabel(accreditation)
          };
        }
      });
    return alreadyAssignedPeople;
  };

  sameOrganisationAccred = () => {
    const accredOptions = this.props.accreditations.map((accreditation) => {

      return {
        value: accreditation.id,
        label: this.accreditationLabel(accreditation)
      };
    });
    return accredOptions;
  };

  assignedAccredName = () => {
    const names = this.state.orderline.accreditation_accesses
      ?.map((access) => {
        return (
          access.accreditation.first_name + " " + access.accreditation.last_name
        );
      })
      .join(", ");
    return names;
  };

  handleOrderlineClick = () => {
    if (this.props.isEsad) {
      this.setState({ selectedColor: "lightblue" });
      this.openEsadModal();
    }
  };

  deadlinePassedWarning = () => {
    if (!this.state.orderline.assignmentDeadlinePassed) {
      return null;
    }

    return <div  className="alert alert-danger" role="alert">
      <p>Please note that the deadline (12:00 Doha Time day before Match) has passed for this Match.</p>
      <p>You can still amend the list of accredited names below, however please notify <a href="mailto:booking@hbs.tv" target="_blank">booking@hbs.tv</a> in parallel of any late adjustment.</p>
    </div>;
  }

  editEsadModal = () => {
    let modalBody =
      this.state.orderline === null ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Modal.Header className="justify-content-start">
            <NotificationsManager
              notificationDOMRef={this.notificationDOMRef}
            />
            <Modal.Title>
              <h4 className="text-primary">
                {"Broadcast Booking Ticket Assignment"}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.showLimitAssignementAlert &&
              this.renderLimitAssignmentAlert()}
            <div className="d-flex">
              <div className="flex-grow-1 font-weight-bold p-2">
                {this.state.orderline.target}
              </div>
              <div className="flex-grow-1 font-weight-bold p-2">
                {this.state.orderline.teams}
              </div>
              <div className="flex-grow-1 font-weight-bold p-2">
                {this.state.orderline.serviceCode} -{" "}
                {this.state.orderline.serviceDescription}
              </div>
            </div>
            <br></br>
            {this.deadlinePassedWarning()}
            <div className="w-750">
              <p className="text-secondary">
                Select up to{" "}
                {this.state.orderline.accreditationAccessesLimitation}{" "}
                {this.singleOrMultiplePerson()}:
              </p>
              <Select
                isMulti
                defaultValue={this.props.isEsad && this.assignedAccredOption()}
                options={this.props.isEsad && this.sameOrganisationAccred()}
                onChange={this.handleAccreChange}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-secondary"
              variant="secondary"
              onClick={(e) => this.closeEsadModal(e)}
            >
              <span className="glyphicon glyphicon-log-in"></span> Exit
            </button>
            <Button
              variant="primary"
              disabled={this.state.showLimitAssignementAlert}
              onClick={this.submitEsad}
            >
              <span className="glyphicon glyphicon-edit"></span> Assign
            </Button>
          </Modal.Footer>
        </div>
      );

    return (
      <Modal
        dialogClassName="max-width-1000"
        size="lg"
        show={this.state.showEsadModal}
        onHide={this.closeEsadModal}
      >
        {modalBody}
      </Modal>
    );
  };

  render() {
    let editable = this.props.editable || false;
    let displayPrices = this.props.displayPrices || false;
    let cancelButton = null;
    let editButton = null;
    let orderline = this.state.orderline;
    //after submit, warm_up_match price is set to 0 from BSS with format eg: "€0.00"
    let orderlineTotalPrice = orderline.calculate_price ? orderline.calculate_price : (orderline.warm_up_match ? 0 : orderline.total)
    const priceInfo = (this.props.canSeePrice && this.props.togglePriceInfo) ? (
      <>
        <td className="unit-price align-top">{this.formatCurrency(orderline.unitPrice, orderline.currency)}</td>
        <td className="discount align-top">{this.formatCurrency(orderline.total_discount, orderline.currency)}</td>
        <td className="total-price align-top">{this.formatCurrency(orderline.calculate_price, orderline.currency)}</td>
      </>
    ) : null

    let displayPriceOrStatus = displayPrices ? (
      <>
        <td className="unit_price align-top">
        {this.formatCurrency(orderline.unitPrice, orderline.currency)}
        </td>
        <td className="quantity align-top">{orderline.quantity}</td>
        <td className="price align-top">
          {this.formatCurrency(
            orderlineTotalPrice,
            orderline.currency
          )}
        </td>
      </>
    ) : (
      <>
        <td className="public_status align-top">
          <acronym title={orderline.longPublicStatus}>
            {orderline.publicStatus}
          </acronym>
        </td>
        <td className="quantity align-top">{orderline.quantity}</td>
        {priceInfo}
      </>
    );

    let displayPriceOrEsad = this.props.isEsad ? (
      <td>
        {this.state.orderline.accreditation_accesses?.length}/
        {this.state.orderline.accreditationAccessesLimitation}
      </td>
    ) : (
      <>
        {displayPriceOrStatus}
      </>
    );

    // if bundleComment exist, it is an bundle detail orderline, can not be edit/cancel
    if (orderline.publicStatus === "NEW" && editable && !orderline.bundleComment) {
      cancelButton = (
        <button
          onClick={(e) =>
            window.confirm("Are you sure to cancel this request?") &&
            this.cancelOrderline(this.props.id)
          }
          className="btn btn-outline-danger btn-sm"
        >
          <i className="fa fa-ban"></i> Cancel
        </button>
      );
      editButton = (
        <button
          onClick={(e) => this.showModalHandler()}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="fa fa-edit"></i> Edit
        </button>
      );
    }

    let timings = null;

    if (orderline.slot) {
      timings = <div>Slot {orderline.slot}</div>;
    }
    if (orderline.startTime || orderline.endTime) {
      timings = (
        <div>
          {orderline.startTime} - {orderline.endTime}
        </div>
      );
    }

    return (
      <tr
        key={orderline.id}
        onClick={(e) => this.handleOrderlineClick()}
        className={this.state.selectedColor}
      >
        <td className="target align-top">
          <div>{orderline.target}</div>
          <div>
            {orderline.teams}{" "}
            {orderline.warm_up_match && (<span className="badge badge-info">Test Match</span>)}{" "}
            {orderline.assignmentDeadlinePassed && (<span className="badge badge-danger">Deadline passed</span>)}
          </div>
        </td>
        <td colSpan={this.props.isEsad ? 5 : 3} className="service align-top">
          <div>
            {orderline.serviceCode} - {orderline.serviceDescription}
          </div>
          {orderline.bundleComment && (
            <div className="bundle-comment"> {orderline.bundleComment} </div>
          )}
          {timings}
          <div className="public_note">{orderline.publicNote}</div>
          {this.props.isEsad && (
            <div className="text-info">{this.assignedAccredName()}</div>
          )}
        </td>
        {displayPriceOrEsad}
        {!this.props.isEsad && editable ? (
          <td className="actions do-not-print">
            {editButton}
            {cancelButton}
          </td>
        ) : null}
        {this.editModal()}
        {this.editEsadModal()}
      </tr>
    );
  }
}

export default Orderline;
