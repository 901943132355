/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Support component names relative to this directory:
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import dva from "dva-no-router";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducerUser from "../store/reducer_user";
import thunk from "redux-thunk";
import { BrowserRouter, Route, Router, HashRouter } from "react-router-dom";
import { Switch } from "react-router";
import Home from "../components/Home";
import Orders from "../components/Orders";
import Orderstepone from "../components/orderlines/orderlineform/Orderstepone";
import Ordersteptwo from "../components/orderlines/orderlineform/Ordersteptwo";
import Orderstepthree from "../components/orderlines/orderlineform/Orderstepthree";
import OrderSummary from "../components/OrderSummary";
import Esads from "../components/esads/Esads";
import "bootstrap/dist/css/bootstrap.css";
import "../css/Orderline.css";
import "../css/Orders.css";
import CosFC from "../components/CosFC";

async function initReact() {
  if (document.getElementById("root") == null) {
    return;
  }
  const store = createStore(reducerUser, applyMiddleware(thunk));

  let svgBadgeContent = null;
  // fetch new badge
  try {
    const response = await fetch("/settings/generate_new_badge.json");
    const data = await response.json();
    svgBadgeContent = data.svg;
  } catch (error) {
    console.error("Error fetching SVG badge:", error);
  }


  var componentRequireContext = require.context("components", true);
  var ReactRailsUJS = require("react_ujs");
  ReactRailsUJS.useContext(componentRequireContext);

  const handleError = (e) => {
    console.error("!!! ERROR !!!", e.message);
  };

  const app = dva({
    onError: handleError,
  });

  app.router(() => {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/orders/new/3" component={Orderstepthree} />
          <Route exact path="/orders/new/2" component={Ordersteptwo} />
          <Route exact path="/orders/new" component={Orderstepone} />
          <Route exact path="/orders" component={Orders} />
          <Route path="/orders/:number" component={OrderSummary} />
          <Route path="/cos" render={(props) => <CosFC {...props} svgBadgeContent={svgBadgeContent} />} />
          <Route
            path="/guidelines"
            render={(props) => (
              <CosFC
                {...props}
                svgBadgeContent={svgBadgeContent}
                filterChaptersBy={(chapter) =>
                  chapter?.cos_services?.some(
                    (cos_service) => !!cos_service.guidelines
                  )
                }
              />
            )}
          />
          <Route
            path="/technical_leaflets"
            render={(props) => (
              <CosFC
                {...props}
                svgBadgeContent={svgBadgeContent}
                filterChaptersBy={(chapter) =>
                  chapter?.cos_services?.some(
                    (cos_service) => !!cos_service.technical_leaflets
                  )
                }
              />
            )}
          />
          <Route path="/esads" component={Esads} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    );
  });

  const App = app.start();
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>,
    document.getElementById("root")
  );
}

initReact();

// document.addEventListener("turbolinks:load", function () {
//   initReact();
// });
//
// document.addEventListener("turbolinks:click", function () {
//   if (document.getElementById("root") != null) {
//     ReactDOM.unmountComponentAtNode(document.getElementById("root"));
//   }
// });

document.addEventListener('DOMContentLoaded', () => {
  initReact();
});
